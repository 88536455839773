<template>
    <section class="content">
        <div class="card">
            <div class="card-body" style="align-self: center; display: flex;">
                <span style="align-self: center; font-size: initial;">Bulan</span>&nbsp;&nbsp;
                <div class="input-group">
                    <datepicker
                        placeholder="Bulan"
                        class="datep"
                        v-model="f_bulan"
                        :options="{format:'yyyy-mm', startView: 'months', minViewMode: 'months', autoclose: true}" 
                        :autoclose=true
                        required
                        />
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                    </div>
                    </div>
                <button style="margin-left: 8px;" type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover" ref="tableuser">
                <thead>
                    <tr>
                        <th style="width:60px;">KELAS</th>
                        <th v-for="n in 31" style="padding: 10px;">{{n}}</th>
                        <th>S</th>
                        <th>I</th>
                        <th>A</th>
                        <th><img src="https://img.icons8.com/metro/16/000000/checkmark.png"/></th>
                        <th><img src="https://img.icons8.com/metro/16/000000/multiply.png"/></th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        
        <div class="p-3"></div>
        <div class="row" style="width: 100%;">
            <div class="col-12 col-sm-6 col-md-6 col-lg-12">
                <table class="table" >
                    <tr>
                        <td>Libur</td>
                        <td><i class="fas fa-minus"></i></td>
                    </tr>
                    <tr>
                        <td>Masuk</td>
                        <td><i class="fas fa-check"></i></td>
                    </tr>
                    <tr>
                        <td>Tidak Masuk</td>
                        <td><i class="fas fa-times"></i></td>
                    </tr>
                </table>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-12">
                    <div class="info-box bg-gradient-info">
                      <span class="info-box-icon"><i class="fas fa-money-bill-wave"></i></span>

                      <div class="info-box-content">
                        <span class="info-box-text" style="font-size: large;">Total Fee Tentor</span>
                        <span class="info-box-number" style="font-size: large;">41,410</span>
                      </div>
                      <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
            </div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
// import vSelect from 'vue-select';
// import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    name: 'Daftarkehadiran',
    components: {
        // vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            f_bulan : '',
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        search: function() {
            this.table.api().ajax.reload();
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
            ajax: "/tentor/rekap_absensi",
            serverSide: false,
            scrollX: true,
            // columns: [
            //     { data: "name" },
            //     { data: "no_hp" },
            // ],
            filter:false,
            sort: false,
            rowCallback: function(row, data) {},
            paramData: function ( d ) {
                d.month = self.f_bulan;
            },
            "footerCallback": function(row, data, start, end, display) {
                var api = this.api();

                // Remove the formatting to get integer data for summation
                var intVal = function(i) {
                    return typeof i === 'string' ?
                        i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                        i : 0;
                };

                // Total over all pages
                var absen = 0;
                var nominal = 0;
                data.map(function(val, i) {
                    absen = absen + parseFloat(val[35]);
                    nominal = parseFloat(val[37]);
                })
                let idr = Intl.NumberFormat("en-ID", {
                    style: "currency",
                    currency: "IDR",
                    useGrouping: true,
                    maximumSignificantDigits: 3,
                });
                $('.info-box-number').html(idr.format(absen * nominal));
            }
        });
    }
}
</script>